import React, { useRef, useState, useEffect } from 'react';
import { Connection } from '@solana/web3.js'
import { Program, AnchorProvider } from '@project-serum/anchor'
import CONFIG from './config'
import swal from 'sweetalert';

const idl = require('./solana_nft_lottery.json')
const opts = {
  commitment: 'finalized',
  preflightCommitment: 'finalized',
  skipPreflight: false,
  confirmTransactionInitialTimeout: 60 * 10 * 1000,
}
const connection = new Connection("https://api.metaplex.solana.com/", opts)

function App() {
  const inputRef = useRef()
  const [lottery, setLottery] = useState()

  useEffect(() => {
    async function f() {
      try {
        const provider = new AnchorProvider(connection, null, opts)
        const program = new Program(idl, CONFIG.CONTRACT_ADDRESS, provider)
        const lottery = await program.account.game.fetch(CONFIG.GAME_ADDRESS)
        setLottery(lottery)
      } catch (err) {
        console.log('err', err)
      }
    }
    f()
  }, [])

  function search() {
    async function f() {
      const myAddress = inputRef.current.value
      if (!myAddress) {
        swal("Input your address to check")
        return false
      }
      for (let i = 0; i < lottery.addresses.length; i++) {
        const owner = await getOnwerOfNFT(lottery.addresses[i])
        if (owner !== myAddress) {
          swal("You are not the Winner")
          return false
        }
      }
      swal("You are the Winner")
    }
    return f()
  }

  async function getOnwerOfNFT(tokenMint) {
    const largestAccounts = await connection.getTokenLargestAccounts(tokenMint);
    const largestAccountInfo = await connection.getParsedAccountInfo(
      largestAccounts.value[0].address
    );
    return largestAccountInfo.value.data.parsed.info.owner
  }

  return (
    <div className="App">
      <form style={{ "position": "relative", "zIndex": 1111 }} id="lotto">
        <input placeholder="Give me soul" ref={inputRef} />
        <button type='button' onClick={search}>Again?</button>
      </form>
    </div>
  );
}

export default App;
